<template>
  <div v-if="scheduledAppointment">
    <v-card>
      <v-card-title primary-title>
        <h3>You are booked!</h3>
      </v-card-title>
      <v-card-text>
        <v-divider/>
        <v-row>
          <v-col cols="12">
            <h3>Here are the details about your appointment:</h3>
            <br/>
            <v-row>
              <v-col>
                <v-simple-table class="elevation-1">
                  <template v-slot:default>
                    <tbody>
                      <tr>
                        <td class="font-weight-bold">Location:</td>
                        <td>{{ scheduledAppointment.vaccineLocationName }}</td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold">Dose</td>
                        <td v-if="currentDoseType === 'First'">First Dose</td>
                        <td v-else-if="currentDoseType === 'Second'">Second Dose</td>
                        <td v-else>Next Dose</td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold">Address:</td>
                        <td><a target="_blank" :href="'http://maps.google.com/maps?q=' + encodeUrl(getLocationAddressString(scheduledAppointment))">{{ getLocationAddressString(scheduledAppointment) }}</a></td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold">Phone:</td>
                        <td><a :href="'tel:' + scheduledAppointment.vaccineLocationPhone">{{ scheduledAppointment.vaccineLocationPhone }}</a></td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold">Date:</td>
                        <td>{{ getPrettyTime(scheduledAppointment.scheduledStartDateTimeUtc) }} to {{ getPrettyTime(scheduledAppointment.scheduledEndDateTimeUtc) }}</td>
                      </tr>
                      <tr>
                        <td class="font-weight-bold">Vaccine Type</td>
                        <td><manufacturer-chip :manufacturerId="scheduledAppointment.vaccineManufacturerId" :manufacturerName="scheduledAppointment.vaccineManufacturerName" v-if="scheduledAppointment.vaccineManufacturerId > 0"></manufacturer-chip></td>
                      </tr>
                      <tr v-if="currentRouteName !== 'proxysignup'" >
                        <td class="font-weight-bold">Add To Calendar</td>
                        <td>
                          <v-btn medium @click="generateIcsItem">
                            <v-icon left>
                              mdi-calendar
                            </v-icon>
                            Click to add to Calendar
                          </v-btn>
                        </td>
                      </tr>
                    </tbody>
                  </template>
                </v-simple-table>
              </v-col>
            </v-row>
            <v-row>
              <v-col>
                <h3>Need to reschedule:</h3>
                <br/>
                <v-btn x-large style="width: 100%;" color="error" @click="releaseAppointment()" :disabled="releasingAppt">Release Appointment</v-btn>
              </v-col>
            </v-row>
          </v-col>
        </v-row>
      </v-card-text>
    </v-card>
  </div>
</template>

<script>
import { mapActions, mapGetters } from 'vuex'
import ical from 'ical-generator'
import moment from 'moment'

export default {
  props: {
    user: {
      type: Object,
      default: () => null,
      required: true
    },
    scheduledAppointment: {
      type: Object,
      default: () => null,
      required: true
    },
    currentDose: {
      type: Object,
      default: () => null,
      required: true
    },
    currentDoseType: {
      type: String,
      default: () => null,
      required: true
    }
  },
  components: {
    manufacturerChip: () => import('@/module/vaccineManufacturer/components/manufacturerChip'),
  },
  watch: {
  },
  computed: {
    currentRouteName: function () {
      return this.$route.name;
    }
  },
  data() {
    return {
      releasingAppt: false
    }
  },
  methods: {
    ...mapActions('users', ['deleteAppointment']),
    releaseAppointment: function () {
      this.releasingAppt = true
      this.deleteAppointment({personId: this.user.personId, appointmentId: this.scheduledAppointment.scheduledAppointmentId}).then((response) => {
        this.$emit('releasedAppointment')
        this.releasingAppt = false
      })
    },
    getLocationAddressStringHtml: function (item) {
      return item.vaccineLocationAddressLine1 + '<br/>' + (item.vaccineLocationAddressLine2 ? item.vaccineLocationAddressLine2 + '<br/>' : '') + item.vaccineLocationCity + ', ' + item.vaccineLocationState + ' ' + item.vaccineLocationZip
    },
    getLocationAddressString: function (item) {
      return item.vaccineLocationAddressLine1 + ' ' + (item.vaccineLocationAddressLine2 ? item.vaccineLocationAddressLine2 + ' ' : '') + item.vaccineLocationCity + ', ' + item.vaccineLocationState + ' ' + item.vaccineLocationZip
    },
    convertToLocal: function (date) {
      return moment.utc(date).local()
    },
    getPrettyTime: function (date) {
      return this.convertToLocal(date).format('MMM Do h:mm a')
    },
    encodeUrl: function (url) {
      return encodeURI(url)
    },
    generateIcsItem: function () {
      let event = ical({
        domain: process.env.VUE_APP_BASE_URI,
        prodId: '//hcahealthcare.com//ical-generator//EN',
        events: [
          {
            start: this.convertToLocal(this.scheduledAppointment.scheduledStartDateTimeUtc),
            end: this.convertToLocal(this.scheduledAppointment.scheduledEndDateTimeUtc),
            summary: 'Covid Vaccination Appointment',
            location: this.getLocationAddressString(this.scheduledAppointment),
            url: process.env.VUE_APP_BASE_URI
          }
        ]
      }).toURL()
      var newWindow = window.open(event)
    },
  },
  mounted() {
  },
};
</script>
